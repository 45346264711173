import Script from 'next/script';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { AnalyticsEvent, AnalyticsEventEnriched, UTM } from '@/core/lib/analytics/analytics.context';
import { AnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import logger from '@/core/lib/log';
import { AbstractRoute } from '@/core/lib/router/route';

const AnalyticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { GTM_ID, GTM_AUTH, GTM_PREVIEW, env } = useEnvContext();

  const [utm, setUtm] = useState<UTM | null>(null);

  const {
    common: { utm_source, utm_medium, utm_campaign },
  } = AbstractRoute.useCommonParams();

  useEffect(() => {
    if (utm_source || utm_medium || utm_campaign) {
      setUtm({
        utmSource: utm_source ?? null,
        utmMedium: utm_medium ?? null,
        utmCampaign: utm_campaign ?? null,
      });
    }
  }, [utm_source, utm_medium, utm_campaign]);

  const getUtmOrigin = useCallback(() => {
    if (!utm) return null;

    const origin = [];

    if (utm.utmSource) {
      origin.push(`s=${utm.utmSource}`);
    }

    if (utm.utmMedium) {
      origin.push(`m=${utm.utmMedium}`);
    }

    if (utm.utmCampaign) {
      origin.push(`c=${utm.utmCampaign}`);
    }

    return origin.join('&').substring(0, 255);
  }, [utm]);

  const send = useCallback(async (event: AnalyticsEvent) => {
    const fullEvent = {
      ...event,
      platform: 'web',
      version: 'web',
    } satisfies AnalyticsEventEnriched;
    if (env === 'production') {
      window.dataLayer.push({ ...fullEvent });
      return;
    }
    logger.info({ fullEvent });
  }, []);

  const value = useMemo(() => ({ send, utm, getUtmOrigin }), [utm]);

  return (
    <AnalyticsContext.Provider value={value}>
      <Script id="consent">
        {`
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('set', 'url_passthrough', true);
        `}
      </Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
        `}
      </Script>
      {children}
      <iframe
        title="gtm"
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
