import React, { useMemo } from 'react';

import { ComponentsContext } from '@/core/lib/components/components.context';
import AppleButton from '@/next/components/AppleButton';
import Content from '@/next/components/Content';
import FacebookButton from '@/next/components/FacebookButton';
import Footer from '@/next/components/Footer';
import GoogleButton from '@/next/components/GoogleButton';
import Head from '@/next/components/Head';
import MetaTitle from '@/next/components/MetaTitle';
import Page from '@/next/components/Page';

const ComponentsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useMemo(
    () => ({
      Page,
      Content,
      AppleButton,
      FacebookButton,
      GoogleButton,
      MetaTitle,
      Footer,
      Head,
      useBackButton: () => {},
    }),
    [],
  );

  return <ComponentsContext.Provider value={value}>{children}</ComponentsContext.Provider>;
};

export default ComponentsProvider;
