import ConversationDomain from '@/core/lib/new-architecture/domain/conversation.domain';
import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import type { ConversationListResult } from '@/core/types/conversation';
import type { CityResult } from '@/core/types/geo';

interface ConversationsDomainInterface {
  conversations: ConversationDomain[];

  getUnread: () => number;
  getTakerCityResult(takerCityId: number): CityResult | null;
}

interface ConversationsDomainConstructor {
  data: ConversationListResult;
  current: CurrentDomain;
}

class ConversationsDomain implements ConversationsDomainInterface {
  private data: ConversationListResult;

  private current: CurrentDomain;

  public conversations: ConversationDomain[];

  constructor({ data, current }: ConversationsDomainConstructor) {
    this.data = data;
    this.current = current;

    this.conversations = this.data.conversations.map(conversation => {
      const donation = this.data.donations.find(d => d.id === conversation.donation)!;
      const messages = this.data.messages.filter(message => message.conv === conversation.id);

      const taker = this.data.users.find(t => t.uuid === conversation.taker);
      const takerCity = this.data.cities?.find(city => city.id === taker?.city);

      return new ConversationDomain({
        data: {
          conversation,
          donation,
          users: this.data.users,
          messages,
          taker_city: takerCity?.id ?? 0,
          is_winner: conversation.is_winner,
          can_write: true,
          no_write_reasons: [],
        },
        messages,
        current: this.current,
        you: this.data.users.find(user => user.uuid === (current.uuid === conversation.giver ? conversation.taker : conversation.giver))!,
        isWinner: conversation.is_winner,
      });
    });
  }

  public getTakerCityResult(takerCityId: number): CityResult | null {
    return this.data.cities?.find(c => c.id === takerCityId) ?? null;
  }

  public getUnread(): number {
    return this.conversations.reduce((unread, conversation) => unread + conversation.getUnread(), 0);
  }
}

export default ConversationsDomain;
