import Head from 'next/head';
import Script from 'next/script';
import React, { useEffect, useMemo, useState } from 'react';

import useMediaQuery from '@/core/hooks/useMediaQuery';
import type { AdProps, AdSize } from '@/core/lib/ads/ads.context';
import { AdsContext, BannerSlot } from '@/core/lib/ads/ads.context';
import { useEnvContext } from '@/core/lib/env/env.context';

const sizes = {
  '728x90': { width: 728, heightAndWidth: 'w-[728px] h-[90px]' },
  '728x480': { width: 728, heightAndWidth: 'w-[728px] h-[480px]' },
  '336x280': { width: 336, heightAndWidth: 'w-[336px] h-[280px]' },
  '336x600': { width: 336, heightAndWidth: 'w-[336px] h-[600px]' },
  '320x100': { width: 320, heightAndWidth: 'w-[320px] h-[100px]' },
  '300x600': { width: 300, heightAndWidth: 'w-[300px] h-[600px]' },
  '300x250': { width: 300, heightAndWidth: 'w-[300px] h-[250px]' },
  '160x600': { width: 160, heightAndWidth: 'w-[160px] h-[600px]' },
} satisfies { [key in AdSize]: { width: number; heightAndWidth: string } };

interface InferredSlot {
  id: string;
  bid: string;
  sizes: [number, number][];
}

const inferSlot = (slot: BannerSlot, size: AdSize): InferredSlot | null => {
  switch (slot) {
    case BannerSlot.DONNONS_DETAIL_TOP:
      if (size === '728x480') {
        return {
          id: 'div-gpt-ad-1407836114266-0',
          bid: '/53015287,1034207/donnons.org_d_728x480_2',
          sizes: [
            [728, 480],
            [728, 280],
            [728, 250],
            [728, 90],
            [640, 480],
            [640, 360],
            [400, 300],
            [360, 280],
            [300, 250],
          ],
        };
      }
      if (size === '336x280') {
        return {
          id: 'div-gpt-ad-1407836156046-0',
          bid: '/53015287,1034207/donnons.org_m_336x280_2',
          sizes: [
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    case BannerSlot.DONNONS_LIST_ITEM:
      if (size === '728x480') {
        return {
          id: 'div-gpt-ad-1407836145265-0',
          bid: '/53015287,1034207/donnons.org_d_728x480_1',
          sizes: [
            [728, 480],
            [728, 280],
            [728, 250],
            [728, 90],
            [640, 480],
            [640, 360],
            [400, 300],
            [360, 280],
            [300, 250],
          ],
        };
      }
      if (size === '336x280') {
        return {
          id: 'div-gpt-ad-1407836282026-0',
          bid: '/53015287,1034207/donnons.org_m_336x280_1',
          sizes: [
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    case BannerSlot.DONNONS_PROFIL_MIDDLE:
      return null;
    case BannerSlot.DONNONS_HOME_SIDE:
      if (size === '336x600') {
        return {
          id: 'div-gpt-ad-1407836039956-0',
          bid: '/53015287,1034207/donnons.org_d_336x600_1',
          sizes: [
            [336, 600],
            [300, 600],
            [160, 600],
            [120, 600],
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    case BannerSlot.DONNONS_LIST_SIDE:
      if (size === '336x600') {
        return {
          id: 'div-gpt-ad-1407836039956-0',
          bid: '/53015287,1034207/donnons.org_d_336x600_1',
          sizes: [
            [336, 600],
            [300, 600],
            [160, 600],
            [120, 600],
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    case BannerSlot.DONNONS_DETAIL_SIDE:
      if (size === '336x600') {
        return {
          id: 'div-gpt-ad-1407836255592-0',
          bid: '/53015287,1034207/donnons.org_d_336x600_2',
          sizes: [
            [336, 600],
            [300, 600],
            [160, 600],
            [120, 600],
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    case BannerSlot.DONNONS_PROFIL_SIDE:
      if (size === '336x600') {
        return {
          id: 'div-gpt-ad-1407836255592-0',
          bid: '/53015287,1034207/donnons.org_d_336x600_2',
          sizes: [
            [336, 600],
            [300, 600],
            [160, 600],
            [120, 600],
            [336, 280],
            [300, 250],
          ],
        };
      }
      return null;
    default:
      return null;
  }
};

const AdPlaceholder: React.FC<Pick<AdProps, 'size'>> = ({ size }) => (
  <div className={`flex ${sizes[size].heightAndWidth} items-center justify-center bg-bg-pale text-[100px] font-bold text-bg-secondary`}>PUB</div>
);

export const Ad: React.FC<AdProps> = ({ size, slot, clone = 0, media }) => {
  // prevent hydration warning
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);

    return () => {
      setIsClient(false);
    };
  }, []);

  const isSm = useMediaQuery('(max-width: 768px)');
  const isMd = useMediaQuery('(min-width: 768px) and (max-width: 1024px) ');
  const isLg = useMediaQuery('(min-width: 1024px) ');

  const medias = {
    sm: isSm,
    md: isMd,
    lg: isLg,
  };

  const { env } = useEnvContext();

  const inferredSlot = inferSlot(slot, size);

  if (!inferredSlot || !isClient) {
    return null;
  }

  const { id, bid, sizes: slotSizes } = inferredSlot;

  if (env !== 'production') {
    return <AdPlaceholder size={size} />;
  }

  const actualMedia = media ? medias[media] : bid.includes('_m_') && (isSm || isMd);

  if (!actualMedia) {
    return null;
  }

  const cloneId = id.replace(/-0$/, `-${clone}`);

  const action = clone > 0 ? `YLHH.bidder.cloneUnit('${bid}', '${cloneId}', true)` : `YLHH.bidder.startAuction('${bid}')`;

  return (
    <div id={cloneId} className={`${sizes[size].heightAndWidth} z-0 block`}>
      <Script id={`tag-yieldlove-${cloneId}`}>
        {`
        googletag.cmd.push(function() {
          googletag.defineSlot('${bid}', ${JSON.stringify(slotSizes)}, '${cloneId}').addService(googletag.pubads());
          googletag.display('${cloneId}');
        });
        yieldlove_cmd.push(function () {
          YLHH.utils.lazyLoad('${cloneId}', function () {
            ${action}
          });
        });
      `}
      </Script>
    </div>
  );
};

const AdSense: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useMemo(
    () => ({
      Ad,
      isLoaded: true,
      Interstitial: () => null,
    }),
    [],
  );

  return (
    <AdsContext.Provider value={value}>
      <Head>
        <script async src="//cdn-a.yieldlove.com/v2/yieldlove.js?donnons.org" />
        <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
      </Head>
      <Script id="yieldlove" strategy="beforeInteractive">
        {`
          yieldlove_prevent_autostart = true;
          var yieldlove_cmd = yieldlove_cmd || [];
        `}
      </Script>
      <Script id="yieldlove-googletag" strategy="beforeInteractive">
        {`
          var googletag = googletag || {};
          googletag.cmd = googletag.cmd || [];
          googletag.cmd.push(function() {
            var slot = googletag.defineOutOfPageSlot('/53015287,1034207/donnons.org_interstitial', googletag.enums.OutOfPageFormat.INTERSTITIAL);
            if (slot) slot.addService(googletag.pubads());
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
            googletag.display(slot);
          });
        `}
      </Script>
      {children}
    </AdsContext.Provider>
  );
};

export default AdSense;
