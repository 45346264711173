import { createContext, useContext } from 'react';

import type { SeoMeta } from '@/core/types/meta';
import type { FooterProps } from '@/next/components/Footer/Footer';
import type { HeadProps } from '@/next/components/Head/Head';

export interface PageProps extends React.PropsWithChildren {
  noPadding?: boolean;
}

export interface ContentProps extends React.PropsWithChildren {
  scroll?: string;
}

export interface MetaTitleProps {
  meta: SeoMeta;
}

export interface ComponentsContextType {
  Page: React.FC<PageProps>;
  Content: React.FC<ContentProps>;
  AppleButton: React.FC;
  FacebookButton: React.FC;
  GoogleButton: React.FC;
  MetaTitle: React.FC<MetaTitleProps>;
  Footer: React.FC<FooterProps>;
  Head: React.FC<HeadProps>;
  useBackButton: (handle: () => void) => void;
}

export const ComponentsContext = createContext<ComponentsContextType | null>(null);

export const useComponentsContext = () => useContext(ComponentsContext) as ComponentsContextType;
