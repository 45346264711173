import type { UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';

class FavoritesRoute extends AbstractRoute {
  static path = '/favoris';

  getPath() {
    return FavoritesRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): FavoritesRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new FavoritesRoute(common);
  }

  isPrivate() {
    return FavoritesRoute.isPrivate;
  }

  resolve() {
    return `/favoris${this.computeParams()}`;
  }
}

export default { FavoritesRoute };
