import React, { useCallback, useEffect, useId, useMemo, useRef } from 'react';

import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { useComponentsContext } from '@/core/lib/components/components.context';
import { useNativeContext } from '@/core/lib/native/native.context';

type ModalType = 'tunnel' | 'popup';

const defaultModalType: ModalType = 'tunnel';

export interface ModalProps extends React.PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  closeOnEscape?: boolean;
  type?: ModalType;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, closeOnEscape = true, type = defaultModalType }) => {
  const { useBackButton } = useComponentsContext();

  const ref = useRef<HTMLDivElement>(null);
  const previousRef = useRef<Element | null>(null);
  const { isNative } = useNativeContext();

  useKeyDownHandler([], 'Escape', (event: KeyboardEvent) => {
    event.preventDefault();

    const highestOrder = Array.from(document.querySelectorAll('div[data-dialog-role="overlay"]'))
      .sort((overlayA, overlayB) => {
        const orderA = Number(overlayA.getAttribute('data-dialog-order'));
        const orderB = Number(overlayB.getAttribute('data-dialog-order'));
        return orderB - orderA;
      })
      .map(o => o.getAttribute('data-dialog-order'))[0];

    if (closeOnEscape && ref.current?.getAttribute('data-dialog-order') === highestOrder) {
      onClose();
    }
  });

  const id = useId();

  const dialogTabletSize = useMemo(() => {
    if (isNative) {
      return type === 'tunnel' ? 'md:col-span-6 md:col-start-2 lg:col-span-6 lg:col-start-4' : 'mt-header-mobile h-fit md:col-span-4 md:col-start-3 lg:col-span-4 lg:col-start-5';
    }
    return type === 'tunnel' ? 'md:col-span-6 md:col-start-2 md:h-fit lg:col-span-6 lg:col-start-4' : 'mt-header-mobile h-fit rounded-2 md:col-span-4 md:col-start-3 lg:col-span-4 lg:col-start-5';
  }, [isNative, type]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      // at mount time when isOpen is false, the overlays do not include the current modal
      const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');
      const selfOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-id')?.value === id);
      const overlaysLength = overlays.length;

      const previousOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-order')?.value === (overlaysLength - 1).toString());

      if (isOpen) {
        // when we mount it we give it the order to control which one is the most recent
        selfOverlay?.setAttribute('data-dialog-order', overlaysLength.toString());

        previousOverlay?.setAttribute('style', 'overflow: hidden');
        // we keep in a ref the previous modal to be sure to unlock it
        previousRef.current = previousOverlay ?? null;
      } else {
        previousRef.current?.setAttribute('style', '');
      }

      // if there is an overlay, lock the scroll otherwise unlock it
      if (overlaysLength > 0) {
        document.body.setAttribute('style', `position: fixed; top: -${window.scrollY}px; left: 0; right: 0`);
      } else {
        document.body.setAttribute('style', '');
      }
    }

    return () => {
      const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');

      if (overlays.length === 0) {
        document.body.setAttribute('style', '');
      }
    };
  }, [isOpen]);

  const handleBackButton = useCallback(() => {
    const overlays = document.querySelectorAll('div[data-dialog-role="overlay"]');
    const selfOverlay = Array.from(overlays).find(overlay => overlay.attributes.getNamedItem('data-dialog-id')?.value === id);
    const isFrontOverlay = overlays.length.toString() === selfOverlay?.attributes.getNamedItem('data-dialog-order')?.value;

    if (isOpen && isFrontOverlay) {
      onClose();
    }
  }, [id, isOpen]);

  useBackButton(handleBackButton);

  if (!isOpen) {
    return null;
  }

  const overlayTabletSize = isNative ? '' : 'md:grid-cols-8 md:gap-x-5 md:px-6 md:pb-space-header md:pt-header-mobile';
  const webStyle = isNative ? '' : 'rounded-3';

  return (
    <div
      ref={ref}
      data-dialog-id={id}
      className={`fixed right-0 top-0 z-50 grid h-screen w-screen grid-cols-4 gap-x-4 overflow-y-auto overflow-x-hidden bg-bg-dark/60 focus-visible:border-none focus-visible:outline-none ${overlayTabletSize} lg:grid-cols-12 lg:gap-x-6 lg:px-24 lg:pt-header-desktop ${type === 'popup' && 'px-4'} !m-0`}
    >
      <div role="dialog" className={`col-span-4 w-full bg-bg-primary ${dialogTabletSize} ${webStyle} p-4`}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
