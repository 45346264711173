import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import type DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import type { UsersPrivateProfilResult, UsersPublicProfilResult } from '@/core/types/profile';

interface UserDomainPublicInfo {
  avatarUrl: string;
  username: string;
  donationDonated: number;
  donationReceived: number;
  city: string | null;
  createdAt: Dayjs;
}

interface UserDomainPrivateInfo {
  email: string;
}

export enum UserActionType {
  REPORT = 'report',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  SHARE = 'share',
}

export type ReportAction = {
  action: UserActionType.REPORT;
  uuid: string;
  text: string;
};

export type BlockAction = {
  action: UserActionType.BLOCK;
  uuid: string;
  text: string;
};

export type UnblockAction = {
  action: UserActionType.UNBLOCK;
  uuid: string;
  text: string;
};

export type ShareAction = {
  action: UserActionType.SHARE;
  uuid: string;
  text: string;
};

export type UserAction = ReportAction | BlockAction | UnblockAction | ShareAction;

export interface UserDomainInterface {
  uuid: string;
  getPublicInfo: () => UserDomainPublicInfo;
  getPrivateInfo: () => UserDomainPrivateInfo | null;
  getActions: () => UserAction[];
  getDonations: () => DonationDomain[];
  isBlocked: () => boolean;
  isPrivate: () => boolean;

  setDonations: (donations: DonationDomain[]) => void;
}

interface UserDomainConstructor {
  data: UsersPublicProfilResult | UsersPrivateProfilResult;
  current: CurrentDomain | null;
  donations?: DonationDomain[];
}

class UserDomain implements UserDomainInterface {
  data: UsersPublicProfilResult | UsersPrivateProfilResult;

  // temporary
  public publicUser: UsersPublicProfilResult | undefined = undefined;

  public privateUser: UsersPrivateProfilResult | undefined = undefined;

  private current: CurrentDomain | null;

  private donations: DonationDomain[];

  constructor({ data, current, donations }: UserDomainConstructor) {
    this.data = data;

    // temporary
    this.publicUser = this.data;
    this.privateUser = 'has_password' in this.data ? this.data : undefined;

    this.current = current;
    this.donations = donations ?? [];
  }

  get uuid() {
    return this.data.uuid;
  }

  public getPublicInfo() {
    return {
      avatarUrl: this.data.avatar_url,
      username: this.data.username,
      donationDonated: this.data.donation_donated,
      donationReceived: this.data.donation_received,
      city: this.getCity(),
      createdAt: dayjs(this.data.created_at),
    };
  }

  public getPrivateInfo() {
    if ('has_password' in this.data) {
      return {
        email: this.data.email,
      };
    }

    return null;
  }

  public getActions() {
    const report = {
      action: UserActionType.REPORT,
      uuid: this.uuid,
      text: 'options.report',
    } satisfies ReportAction;

    const block = {
      action: UserActionType.BLOCK,
      uuid: this.uuid,
      text: 'options.block',
    } satisfies BlockAction;

    const unblock = {
      action: UserActionType.UNBLOCK,
      uuid: this.uuid,
      text: 'options.unblock',
    } satisfies UnblockAction;

    // it means it is our profile and we can only share it
    if ('has_password' in this.data) {
      return [];
    }

    // since we have to be connected the actions block and report
    // are for now excluded
    if (!this.current) {
      return [];
    }

    return [report, this.isBlocked() ? unblock : block];
  }

  public getDonations() {
    return this.donations;
  }

  public isBlocked() {
    return !!this.current?.hasBlocked(this.uuid);
  }

  public isPrivate() {
    return !!this.privateUser;
  }

  public setDonations(donations: DonationDomain[]) {
    this.donations = donations;
  }

  private getCity(): string | null {
    if (this.data.sub) {
      const userSub = this.data.subs?.find(sub => sub.id === this.data.sub);

      if (userSub) {
        return userSub.name;
      }
    }

    const userCity = this.data.cities?.find(city => city.id === this.data.city);
    const userAdmin = this.data.admins?.find(admin => admin.id === userCity?.admin);

    if (userCity) {
      if (userAdmin) {
        return `${userCity.name} (${userAdmin.name})`;
      }

      return userCity.name;
    }

    return null;
  }
}

export default UserDomain;
