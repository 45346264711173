import type React from 'react';
import { createContext, useContext } from 'react';

export enum BannerSlot {
  DONNONS_DETAIL_TOP = 'DONNONS_DETAIL_TOP',
  DONNONS_LIST_ITEM = 'DONNONS_LIST_ITEM',
  DONNONS_PROFIL_MIDDLE = 'DONNONS_PROFIL_MIDDLE',
  DONNONS_HOME_SIDE = 'DONNONS_HOME_SIDE',
  DONNONS_LIST_SIDE = 'DONNONS_LIST_SIDE',
  DONNONS_DETAIL_SIDE = 'DONNONS_DETAIL_SIDE',
  DONNONS_PROFIL_SIDE = 'DONNONS_PROFIL_SIDE',
}

export enum InterstitialSlot {
  INTERSTITIEL_OUVERTURE = 'INTERSTITIEL_OUVERTURE',
}

export type AdSize = '728x90' | '728x480' | '300x600' | '160x600' | '336x280' | '336x600' | '300x250' | '320x100';

export interface AdProps {
  size: AdSize;
  slot: BannerSlot;
  format?: string;
  clone?: number;
  media?: 'sm' | 'md' | 'lg';
}

export type InterstitialStrategy = 'first-version';

export interface InterstitialProps {
  slot: InterstitialSlot;
  strategy: InterstitialStrategy;
}

export interface AdsContextType {
  Ad: React.FC<AdProps>;
  isLoaded: boolean;
  Interstitial: React.FC<InterstitialProps>;
}

export const AdsContext = createContext<AdsContextType | null>(null);

export const useAdsContext = () => useContext(AdsContext) as AdsContextType;
