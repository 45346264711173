import * as yup from 'yup';

import type { CommonSearchParams, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';

const donationPageParamsSchema = yup.object({ id: yup.number().required() });

class DonationRoute extends AbstractRoute {
  private id: number;

  static path = '/don/:id';

  getPath() {
    return DonationRoute.path;
  }

  static isPrivate = false;

  constructor(id: number, common?: CommonSearchParams) {
    super(common);
    this.id = id;
  }

  static useParams(): UseParamsReturn<typeof donationPageParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = donationPageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search: {}, common };
  }

  static init(url: URL): DonationRoute {
    const params = this.exec(url.pathname);
    const casted = donationPageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const common = this.searchParamsToCommon(url.searchParams);

    return new DonationRoute(casted.id, common);
  }

  isPrivate() {
    return DonationRoute.isPrivate;
  }

  resolve() {
    return `/don/${this.id}${this.computeParams()}`;
  }
}

const modifyDonationPageParamsSchema = yup.object({ id: yup.number().required() });

class ModifyDonationRoute extends AbstractRoute {
  private id: number;

  static path = '/don/:id/modifier';

  getPath() {
    return ModifyDonationRoute.path;
  }

  static isPrivate = true;

  constructor(id: number, common?: CommonSearchParams) {
    super(common);
    this.id = id;
  }

  static useParams(): UseParamsReturn<typeof donationPageParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = modifyDonationPageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search: {}, common };
  }

  static init(url: URL): ModifyDonationRoute {
    const params = this.exec(url.pathname);
    const casted = modifyDonationPageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const common = this.searchParamsToCommon(url.searchParams);

    return new ModifyDonationRoute(casted.id, common);
  }

  isPrivate() {
    return ModifyDonationRoute.isPrivate;
  }

  resolve() {
    return `/don/${this.id}/modifier${this.computeParams()}`;
  }
}

export default { DonationRoute, ModifyDonationRoute };
