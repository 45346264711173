import type { Dayjs } from 'dayjs';
import * as yup from 'yup';

import type { PublicDonationResult } from '@/core/types/donation';
import { publicDonationResultSchema } from '@/core/types/donation';
import { adminResultSchema, cityResultSchema, countryResultSchema, subResultSchema } from '@/core/types/geo';
import type { UsersPublicProfilResult } from '@/core/types/profile';
import type { UserResult } from '@/core/types/users';
import { userResultSchema } from '@/core/types/users';

export enum ConversationState {
  IMPORTANT = 'important',
  NORMAL = 'normal',
  ARCHIVED = 'archived',
}

export const conversationResultSchema = yup.object({
  id: yup.number().required(),
  is_winner: yup.boolean().required(),
  donation: yup.number().required(),
  giver: yup.string().required(),
  taker: yup.string().required(),
  last_msg_id: yup.number().required(),
  giver_read: yup.number().nullable(),
  giver_unread: yup.number().required(),
  taker_read: yup.number().nullable(),
  taker_unread: yup.number().required(),
  giver_state: yup.mixed<ConversationState>().oneOf(Object.values(ConversationState)).required(),
  taker_state: yup.mixed<ConversationState>().oneOf(Object.values(ConversationState)).required(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
});

export type ConversationResult = yup.InferType<typeof conversationResultSchema>;

export enum MessageCode {
  MESSAGE = 'message',
  CREATED = 'created',
  RESERVED = 'reserved',
  RESERVED_OTHER = 'reserved_other',
  UNRESERVED = 'unreserved',
  UNRESERVED_OTHER = 'unreserved_other',
  GIVEN = 'given',
  GIVEN_OTHER = 'given_other',
  DONE = 'done',
  RECEIVED = 'received',
  RECEIVED_OTHER = 'received_other',
  GIVER_BLOCK = 'giver_block',
  TAKER_BLOCK = 'taker_block',
  GIVER_UNBLOCK = 'giver_unblock',
  TAKER_UNBLOCK = 'taker_unblock',
  GIVER_ARCHIVED = 'giver_archived',
  TAKER_ARCHIVED = 'taker_archived',
}

export const messageResultSchema = yup.object({
  id: yup.number().required(),
  conv: yup.number().required(),
  code: yup.mixed<MessageCode>().oneOf(Object.values(MessageCode)).required(),
  author: yup.string().nullable(),
  body: yup.string().nullable(),
  media: yup.string().nullable(),
  is_deleted: yup.boolean().required(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
});

export type MessageResult = yup.InferType<typeof messageResultSchema>;

export const conversationListResult = yup.object({
  conversations: yup.array(conversationResultSchema).required(),
  messages: yup.array(messageResultSchema).required(),
  donations: yup.array(publicDonationResultSchema).required(),
  users: yup.array(userResultSchema).required(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
});
export type ConversationListResult = yup.InferType<typeof conversationListResult>;

export enum NoWriteReason {
  DONATION_STATE = 'donation_state', // refus ou fini mais pas bénéficiaire
  GIVER_STATE = 'giver_state', // moderation, or deleted
  GIVER_BLOCK = 'giver_block',
  TAKER_STATE = 'taker_state',
  TAKER_BLOCK = 'taker_block',
}

export const conversationItemResult = yup.object({
  taker_city: yup.number().required(),
  taker_sub: yup.number().nullable(),
  is_winner: yup.boolean().required(),
  can_write: yup.boolean().required(),
  no_write_reasons: yup.array(yup.mixed<NoWriteReason>().oneOf(Object.values(NoWriteReason)).required()).required(),
  conversation: conversationResultSchema.required(),
  donation: publicDonationResultSchema.required(),
  messages: yup.array(messageResultSchema).required(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
  users: yup.array(userResultSchema.required()).nullable(),
});
export type ConversationItemResult = yup.InferType<typeof conversationItemResult>;

export enum Participant {
  ME = 'me',
  YOU = 'you',
}

export interface Message {
  id: number;
  code: MessageCode;
  body?: string | null;
  participant?: Participant;
  media?: string | null;
  isDeleted?: boolean;
  date: Dayjs;
}

export interface Conversation {
  id: number | 'create';
  giver: Participant;
  taker: Participant;
  unread: number;
  lastMessage: Message;
  messages: Message[];
  donation: PublicDonationResult;
  you: UserResult | UsersPublicProfilResult;
  isWinner?: boolean;
}

export interface Context {
  data: Conversation[];
}

export const conversationsConversationFormSchema = yup.object({
  message: yup.string().min(1, 'errors.min-1').max(1000, 'errors.max-1000').nullable(),
  media: yup.string().nullable(),
});

export type ConversationsConversationForm = yup.InferType<typeof conversationsConversationFormSchema>;
