import React from 'react';

import Avatar from '@/core/components/Avatar';
import Button from '@/core/components/Button';
import Icons from '@/core/components/Icons';
import MenuCard from '@/core/components/MenuCard';
import MenuItem from '@/core/components/MenuItem';
import NotifBadge from '@/core/components/NotifBadge';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useGlobalContext } from '@/core/lib/global/global.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import Store from '@/core/lib/new-architecture/store';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface ProfileMenuProps {}

const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const { Link: RouterLink } = useRouterContext();
  const { t } = useTranslationContext(['profile.profile']);
  const { logout, openAuth } = useAuthContext();
  const { send } = useAnalyticsContext();
  const { data: current } = Store.current.useCurrent();
  const { isNative } = useNativeContext();
  const {
    give: { onOpen: onOpenGive },
  } = useGlobalContext();

  const onLogout = async () => {
    await send({ event: AnalyticsEventType.LOGOUT });
    await logout();
  };

  const { data: conversations } = Store.conversations.useConversations();
  const conversationsUnread = conversations?.getUnread() ?? 0;

  const { data: favorites } = Store.favorites.useFavorites();
  const favoritesUnread = favorites?.getUnread() ?? 0;

  const onClickOpenGive = async () => {
    await send({ event: AnalyticsEventType.CLICK_GIVE, where: 'Menu Profil' });
    onOpenGive();
  };

  if (!current) {
    if (isNative) return null;
    return (
      <div className="px-5 pt-4">
        <div className="relative flex w-full">
          <p className="lg:text-body-primary w-full text-center font-semibold text-transparent lg:block">{t('menu', { ns: 'profile.profile' })}</p>
          <div className="absolute right-4 block cursor-pointer lg:hidden">
            <Icons icon="close" size="24" />
          </div>
        </div>

        <div className="mt-2 flex w-full flex-col space-y-7 px-4 lg:hidden">
          <Button
            onClick={async () => {
              await send({ event: AnalyticsEventType.CLICK_AUTH, where: 'Menu Profil + Give' });
              openAuth();
            }}
            variant="give"
            size="medium"
            iconLeft="add"
          >
            {t('components.header.give', { ns: 'common' })}
          </Button>

          <RouterLink href={new Routes.ExternalRoute('vision')} className="text-body-secondary shrink-0 font-normal text-content-secondary">
            {t('components.header.vision', { ns: 'common' })}
          </RouterLink>

          <RouterLink href={new Routes.ExternalRoute('faq')} className="text-body-secondary shrink-0 font-normal text-content-secondary">
            {t('components.header.faq', { ns: 'common' })}
          </RouterLink>

          <RouterLink href={new Routes.ExternalRoute('blog')} className="text-body-secondary shrink-0 font-normal text-content-secondary">
            {t('components.header.blog', { ns: 'common' })}
          </RouterLink>

          <button type="button" className="flex w-full items-center justify-between space-x-1 border-t border-stroke-tertiary bg-bg-primary py-4" onClick={openAuth}>
            <div className="flex items-center space-x-3">
              <Icons icon="log-out" size="16" />
              <div className="flex flex-col items-start">
                <p className="text-body-secondary font-medium text-content-primary">{t('menu-items.login', { ns: 'profile.profile' })}</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }

  const co2Icon = (
    <div className="flex size-12 items-center justify-center rounded-2 bg-bg-button-primary-default/20">
      <Icons weight="bold" icon="seedling" size="32" color="primary" />
    </div>
  );

  const { avatarUrl, username, co2 } = current.getInfo();

  return (
    <div className={`overflow-y-auto ${isNative ? '' : 'px-5 pt-4'}`}>
      <div className="mb-4 flex w-full">
        {!isNative && (
          <>
            <p className="text-body-primary w-full text-center font-semibold text-transparent lg:block lg:text-content-primary">{t('menu', { ns: 'profile.profile' })}</p>

            <div className="absolute right-4 top-4 flex size-8 shrink-0 cursor-pointer items-center justify-center rounded-full hover:bg-bg-button-ghost-hover">
              <Icons icon="close" size="24" />
            </div>
          </>
        )}
      </div>
      <div className="w-full space-y-2">
        <MenuCard href={new Routes.PublicProfileRoute(current.uuid)} leftIcon={<Avatar avatarUrl={avatarUrl} size="48" />}>
          <p className="text-body-primary truncate font-bold text-content-primary">{username ?? t('profile-recap.default-label', { ns: 'profile.profile' })}</p>
          <p className="text-caption-primary font-medium text-content-placeholder">{t('profile-recap.detail', { ns: 'profile.profile' })}</p>
        </MenuCard>

        {/* for now disabled */}
        {/* eslint-disable-next-line no-constant-binary-expression */}
        {false && (
          <MenuCard leftIcon={co2Icon}>
            <div className="my-0.5">
              <p className="text-caption-primary font-medium text-content-placeholder">{t('counter-recap.label', { ns: 'profile.profile' })}</p>
              <p className="text-title font-bold text-content-primary">{`${co2} ${t('counter-recap.emmissions', { ns: 'profile.profile' })}`}</p>
            </div>
          </MenuCard>
        )}
      </div>

      {!isNative && (
        <>
          <div className="my-7 flex w-full flex-col px-4 lg:hidden">
            <Button onClick={onClickOpenGive} variant="give" size="medium" iconLeft="add">
              {t('components.header.give', { ns: 'common' })}
            </Button>
          </div>
          <div className="relative mt-2 flex w-full flex-col">
            <div className="absolute left-8 top-1">
              <NotifBadge unread={conversationsUnread} size="small" />
            </div>
            <MenuItem href={new Routes.ConversationsRoute()} content={t('menu-items.messages', { ns: 'profile.profile' })} leftIcon={<Icons icon="message-square" size="24" />} />
          </div>
        </>
      )}

      <div className="mt-2 w-full">
        <div className="relative">
          <div className="absolute left-8 top-1">
            <NotifBadge unread={favoritesUnread} size="small" />
          </div>
          <MenuItem href={new Routes.FavoritesRoute()} content={t('menu-items.favorites', { ns: 'profile.profile' })} leftIcon={<Icons icon="heart" size="24" />} />
        </div>

        <MenuItem href={new Routes.ProfilePostsRoute()} content={t('menu-items.posts', { ns: 'profile.profile' })} leftIcon={<Icons icon="gift" size="24" />} />
        <MenuItem border href={new Routes.ProfileSettingsRoute()} content={t('menu-items.settings', { ns: 'profile.profile' })} leftIcon={<Icons icon="settings" size="24" />} />

        <div className="flex flex-col">
          <RouterLink href={new Routes.ExternalRoute('vision')} className="text-body-secondary w-full shrink-0 px-3 py-4 font-normal text-content-secondary">
            {t('components.header.vision', { ns: 'common' })}
          </RouterLink>

          <RouterLink href={new Routes.ExternalRoute('faq')} className="text-body-secondary w-full shrink-0 px-3 py-4 font-normal text-content-secondary">
            {t('components.header.faq', { ns: 'common' })}
          </RouterLink>

          <RouterLink href={new Routes.ExternalRoute('blog')} className="text-body-secondary w-full shrink-0 px-3 py-4 font-normal text-content-secondary">
            {t('components.header.blog', { ns: 'common' })}
          </RouterLink>
        </div>

        <button type="button" className="flex w-full items-center justify-between space-x-1 border-t border-stroke-tertiary bg-bg-primary p-4" onClick={onLogout}>
          <div className="flex items-center space-x-3">
            <Icons icon="log-out" size="24" />
            <div className="flex flex-col items-start">
              <p className="text-body-secondary font-medium text-content-primary">{t('menu-items.log-out', { ns: 'profile.profile' })}</p>
            </div>
          </div>
          <div className="mx-2 flex items-center">
            <Icons icon="chevron-right" size="20" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default ProfileMenu;
