import { createContext, useContext } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];

    gtag: (...args: any[]) => void;
  }
}

export enum AnalyticsEventType {
  SIGN_UP_COMPLETE = 'sign_up_complete',
  CLICK_LOGIN = 'click_login',
  LOGIN = 'login',
  LOGOUT = 'logout',
  FORGOTTEN_PASSWORD = 'forgotten_password',
  MODIFIED_PASSWORD = 'modified_password',
  MODIFIED_EMAIl = 'modified_email',
  DELETED_ACCOUNT = 'deleted_account',
  COMPLETED_POST = 'completed_post',
  POST_CREATED = 'post_created',
  POST_UPDATED = 'post_updated',
  CLICK_GIVE = 'click_give',
  DONATION_FORM_STEP_1 = 'donation_form_step_1',
  DONATION_FORM_STEP_2 = 'donation_form_step_2',
  DONATION_FORM_STEP_3 = 'donation_form_step_3',
  CATEGORY_OBJECT_NOT_FOUND = 'category_object_not_found',
  SEARCH_AN_OBJECT = 'search_an_object',
  CLICK_AUTH = 'click_auth',
  CLICK_SIGN_UP = 'click_sign_up',
}

export type AnalyticsEvent =
  | { event: AnalyticsEventType.SIGN_UP_COMPLETE; origin: 'email' | 'facebook' | 'google' | 'apple' }
  | { event: AnalyticsEventType.CLICK_LOGIN; origin: 'email' | 'facebook' | 'google' | 'apple' }
  | { event: AnalyticsEventType.LOGIN; origin: 'email' | 'facebook' | 'google' | 'apple' }
  | { event: AnalyticsEventType.LOGOUT }
  | { event: AnalyticsEventType.FORGOTTEN_PASSWORD }
  | { event: AnalyticsEventType.MODIFIED_PASSWORD }
  | { event: AnalyticsEventType.MODIFIED_EMAIl }
  | { event: AnalyticsEventType.DELETED_ACCOUNT }
  | { event: AnalyticsEventType.COMPLETED_POST }
  | { event: AnalyticsEventType.DONATION_FORM_STEP_1; donation_form_type: 'creation' | 'update' }
  | { event: AnalyticsEventType.DONATION_FORM_STEP_2; donation_form_type: 'creation' | 'update' }
  | { event: AnalyticsEventType.DONATION_FORM_STEP_3; donation_form_type: 'creation' | 'update' }
  | { event: AnalyticsEventType.POST_CREATED }
  | { event: AnalyticsEventType.CLICK_GIVE; where: string }
  | { event: AnalyticsEventType.POST_UPDATED }
  | { event: AnalyticsEventType.CATEGORY_OBJECT_NOT_FOUND; category_name: string }
  | { event: AnalyticsEventType.SEARCH_AN_OBJECT; title: string | null; loc_name: string | null; category_name: string | null }
  | { event: AnalyticsEventType.CLICK_AUTH; where: string }
  | { event: AnalyticsEventType.CLICK_SIGN_UP; where: string };

export type AnalyticsEventEnriched = AnalyticsEvent & {
  platform: 'android' | 'ios' | 'web';
  version: string;
};

export interface UTM {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
}

export interface AnalyticsContextType {
  send: (event: AnalyticsEvent) => Promise<void>;
  utm: UTM | null;
  getUtmOrigin: () => string | null;
}

export const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const useAnalyticsContext = () => useContext(AnalyticsContext) as AnalyticsContextType;
