import dayjs from 'dayjs';
import { useMemo } from 'react';

import ConversationDomain from '@/core/lib/new-architecture/domain/conversation.domain';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';
import { ConversationState, MessageCode, type MessageResult } from '@/core/types/conversation';

const useCreateConversation = (donationId: number | undefined): StoreData<ConversationDomain> => {
  const { data: current, isLoading: currentIsLoading } = Store.current.useCurrent();
  const { data: donation, isLoading: donationIsLoading } = Store.donation.useDonation(donationId);

  const isLoading = donationIsLoading || currentIsLoading;

  const data = useMemo(() => {
    if (!donation || !current) {
      return null;
    }

    const you = donation.getUser();
    const conversation = {
      id: 0,
      donation: 0,
      giver: you.uuid,
      taker: current.uuid,
      last_msg_id: 0,
      giver_read: 0,
      giver_unread: 0,
      taker_read: 0,
      taker_unread: 0,
      giver_state: ConversationState.NORMAL,
      taker_state: ConversationState.NORMAL,
      created_at: '',
      updated_at: '',
      is_winner: false,
    };

    const message = {
      id: 0,
      conv: 0,
      code: MessageCode.CREATED,
      author: null,
      body: null,
      media: null,
      is_deleted: false,
      created_at: dayjs().toString(),
      updated_at: dayjs().toString(),
    } satisfies MessageResult;

    const donationAndTakerCities = donation.data.cities ?? [];
    const takerCity = current.getCityResult();

    if (takerCity) {
      donationAndTakerCities.push(takerCity);
    }

    const conv = {
      conversation,
      messages: [message],
      donation: donation.data.donation,
      users: [you],
      taker_city: current.data.city,
      taker_sub: current.data.sub,
      is_winner: false,
      can_write: true,
      subs: donation.data.subs,
      cities: donationAndTakerCities,
      admins: donation.data.admins,
      no_write_reasons: [],
    };

    return new ConversationDomain({ data: conv, messages: [message], you, current });
  }, [donation, current]);

  return {
    data,
    isLoading,
    notFound: false,
  };
};

export default { useCreateConversation };
