// const DonationPageParams = yup.object({ id: yup.number().required() });
// const donation = makeRoute(({ id }) => `/don/${id}`, DonationPageParams);
import * as yup from 'yup';

import { ConversationsDisplay } from '@/core/lib/new-architecture/api/conversations.api';
import type { CommonSearchParams, Empty, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';
import { ConversationState } from '@/core/types/conversation';

const conversationsSearchParamsSchema = yup.object({
  states: yup
    .array(yup.mixed<ConversationState>().required().oneOf(Object.values(ConversationState)))
    .optional()
    .default([ConversationState.NORMAL, ConversationState.IMPORTANT]),
  display: yup.mixed<ConversationsDisplay>().oneOf(Object.values(ConversationsDisplay)).optional().default(ConversationsDisplay.ALL),
  donations: yup.array(yup.number().required()).optional(),
  noSelect: yup.boolean().optional(),
});

type ConversationsParams = Partial<yup.InferType<typeof conversationsSearchParamsSchema>>;

class ConversationsRoute extends AbstractRoute {
  static path = '/conversations';

  getPath() {
    return ConversationsRoute.path;
  }

  static isPrivate = true;

  private params: ConversationsParams;

  constructor(params?: ConversationsParams, common?: CommonSearchParams) {
    super(common);
    const inferred = conversationsSearchParamsSchema.cast(params, { stripUnknown: true, assert: false });
    this.params = inferred;
  }

  static useParams(): UseParamsReturn<Empty, typeof conversationsSearchParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = conversationsSearchParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page: {}, search, common };
  }

  static init(url: URL): ConversationsRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    const all = Array.from(url.searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as CommonSearchParams);

    const search = conversationsSearchParamsSchema.cast(all, { stripUnknown: true, assert: true });

    return new ConversationsRoute(search, common);
  }

  isPrivate() {
    return ConversationsRoute.isPrivate;
  }

  resolve() {
    return `/conversations${this.computeParams(this.params)}`;
  }
}

const conversationPageParamsSchema = yup.object({ id: yup.number().required() });

const conversationSearchParamsSchema = yup.object({
  states: yup
    .array(yup.mixed<ConversationState>().required().oneOf(Object.values(ConversationState)))
    .optional()
    .default([ConversationState.NORMAL, ConversationState.IMPORTANT]),
  display: yup.mixed<ConversationsDisplay>().required().oneOf(Object.values(ConversationsDisplay)).optional().default(ConversationsDisplay.ALL),
  donations: yup.array(yup.number().required()).optional(),
});

type ConversationParams = yup.InferType<typeof conversationSearchParamsSchema>;

class ConversationRoute extends AbstractRoute {
  private id: number;

  getPath() {
    return ConversationRoute.path;
  }

  static path = '/conversation/:id';

  static isPrivate = true;

  private params: ConversationsParams;

  constructor(id: number, params?: ConversationParams, common?: CommonSearchParams) {
    super(common);
    this.id = id;
    const inferred = conversationSearchParamsSchema.cast(params, { stripUnknown: true, assert: false });
    this.params = inferred;
  }

  static useParams(): UseParamsReturn<typeof conversationPageParamsSchema, typeof conversationSearchParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = conversationPageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    const search = conversationSearchParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search, common };
  }

  static init(url: URL): ConversationRoute {
    const params = this.exec(url.pathname);
    const casted = conversationPageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const common = this.searchParamsToCommon(url.searchParams);

    const all = Array.from(url.searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as CommonSearchParams);

    const search = conversationSearchParamsSchema.cast(all, { stripUnknown: true, assert: true });

    return new ConversationRoute(casted.id, search, common);
  }

  isPrivate() {
    return ConversationRoute.isPrivate;
  }

  resolve() {
    return `/conversation/${this.id}${this.computeParams(this.params)}`;
  }
}

const conversationFromDonationPageParamsSchema = yup.object({ donation: yup.number().required() });

class ConversationFromDonationRoute extends AbstractRoute {
  private donation: number;

  getPath() {
    return ConversationFromDonationRoute.path;
  }

  static path = '/conversation/donation/:donation';

  static isPrivate = true;

  constructor(donation: number, common?: CommonSearchParams) {
    super(common);
    this.donation = donation;
  }

  static useParams(): UseParamsReturn<typeof conversationFromDonationPageParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = conversationFromDonationPageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search: {}, common };
  }

  static init(url: URL): ConversationFromDonationRoute {
    const params = this.exec(url.pathname);
    const casted = conversationFromDonationPageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const common = this.searchParamsToCommon(url.searchParams);

    return new ConversationFromDonationRoute(casted.donation, common);
  }

  isPrivate() {
    return ConversationFromDonationRoute.isPrivate;
  }

  resolve() {
    return `/conversation/donation/${this.donation}${this.computeParams()}`;
  }
}

export default { ConversationsRoute, ConversationRoute, ConversationFromDonationRoute };
