import Head from 'next/head';
import React from 'react';
import type { Organization, SearchAction, WebSite, WithContext } from 'schema-dts';

import SchemaOrg from '@/core/components/SchemaOrg';
import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, useEnvContext, valuesFromProcess } from '@/core/lib/env/env.context';
import { useRouterContext } from '@/core/lib/router/router.context';

const HeadComponent: React.FC = () => {
  const { env, BASE_URL } = useEnvContext();
  const { currentPathname } = useRouterContext();

  const title = 'Site de don d’objets';
  const description = 'Donnez et récupérez gratuitement des objets à côté de chez vous, facilement et rapidement ! Des milliers d’annonces chaque jour pour donner une seconde vie à vos objets.';
  const icon = `${BASE_URL}/assets/sigle.png`;

  const potentialAction = {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}/catalogue?title={search_term_string}`,
    },
    // @ts-expect-error - https://github.com/google/schema-dts/issues/114
    'query-input': 'required name=search_term_string',
  } satisfies SearchAction;

  const websiteSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: BASE_URL,
    inLanguage: 'fr',
    name: title,
    image: icon,
    headline: 'Ne jetons plus, donnons !',
    sameAs: ['https://www.facebook.com/donnons.org', 'https://twitter.com/donnonsOrg'],
    potentialAction,
  } satisfies WithContext<WebSite>;

  const organisationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Donnons',
    url: BASE_URL,
    logo: icon,
  } satisfies WithContext<Organization>;

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="description" name="description" lang="fr" content={description} />
      <meta name="author" content="Donnons" />

      <link key="canonical" rel="canonical" href={`${BASE_URL}${currentPathname}`} />
      <link key="alternate" rel="alternate" hrefLang="fr" href={`${BASE_URL}${currentPathname}`} />

      {/* OG */}
      <meta key="og-title" property="og:title" content={title} />
      <meta key="og-description" property="og:description" content={description} />
      <meta key="og-image" property="og:image" content={icon} />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:url" content="https://donnons.org" />
      <meta property="og:app_id" content="634973656547530" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta key="twitter-title" name="twitter:title" content={title} />
      <meta key="twitter-description" name="twitter:description" content={description} />
      <meta key="twitter-image" name="twitter:image:src" content={icon} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@donnonsOrg" />
      <meta name="twitter:site" content="@donnonsOrg" />

      <link rel="shortcut icon" type="image/png" href={icon} />

      {/* index ? */}
      {env === 'preprod' ? <meta name="robots" content="noindex, nofollow" /> : <meta name="robots" content="index, follow" />}

      <SchemaOrg schema={websiteSchema} />
      <SchemaOrg schema={organisationSchema} />

      {/* PWA */}
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/icon-192x192.png" />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Head>
  );
};

export default HeadComponent;
