import { AbstractRoute } from '@/core/lib/router/route';

class NotFound extends AbstractRoute {
  static path = 'not-found';

  getPath() {
    return NotFound.path;
  }

  static isPrivate = false;

  static init(url: URL): NotFound {
    const common = this.searchParamsToCommon(url.searchParams);
    return new NotFound(common);
  }

  isPrivate() {
    return NotFound.isPrivate;
  }

  resolve() {
    return window.location.pathname;
  }
}

export default NotFound;
