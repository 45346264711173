import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';

import Add from '@/core/components/Icons/icons/Add';
import AlertDanger from '@/core/components/Icons/icons/AlertDanger';
import Archive from '@/core/components/Icons/icons/Archive';
import ArrowLeft from '@/core/components/Icons/icons/ArrowLeft';
import ArrowRight from '@/core/components/Icons/icons/ArrowRight';
import ArrowUp from '@/core/components/Icons/icons/ArrowUp';
import Award from '@/core/components/Icons/icons/Award';
import Bell from '@/core/components/Icons/icons/Bell';
import Block from '@/core/components/Icons/icons/Block';
import Bookmark from '@/core/components/Icons/icons/Bookmark';
import Camera from '@/core/components/Icons/icons/Camera';
import CarouselChevronLeft from '@/core/components/Icons/icons/CarouselChevronLeft';
import CarouselChevronRight from '@/core/components/Icons/icons/CarouselChevronRight';
import Check from '@/core/components/Icons/icons/Check';
import CheckCircle from '@/core/components/Icons/icons/CheckCircle';
import CheckSquare from '@/core/components/Icons/icons/CheckSquare';
import ChevronLeft from '@/core/components/Icons/icons/ChevronLeft';
import ChevronRight from '@/core/components/Icons/icons/ChevronRight';
import ChevronsLeft from '@/core/components/Icons/icons/ChevronsLeft';
import ChevronsRight from '@/core/components/Icons/icons/ChevronsRight';
import Clock from '@/core/components/Icons/icons/Clock';
import Close from '@/core/components/Icons/icons/Close';
import CornerDownRight from '@/core/components/Icons/icons/CornerDownRight';
import CrossHair from '@/core/components/Icons/icons/CrossHair';
import Edit from '@/core/components/Icons/icons/Edit';
import Eye from '@/core/components/Icons/icons/Eye';
import EyeOff from '@/core/components/Icons/icons/EyeOff';
import Feather from '@/core/components/Icons/icons/Feather';
import Filter from '@/core/components/Icons/icons/Filter';
import Flag from '@/core/components/Icons/icons/Flag';
import Gift from '@/core/components/Icons/icons/Gift';
import Heart from '@/core/components/Icons/icons/Heart';
import HelpCircle from '@/core/components/Icons/icons/HelpCircle';
import Image from '@/core/components/Icons/icons/Image';
import Info from '@/core/components/Icons/icons/Info';
import Lock from '@/core/components/Icons/icons/Lock';
import LogOut from '@/core/components/Icons/icons/LogOut';
import Mail from '@/core/components/Icons/icons/Mail';
import Menu from '@/core/components/Icons/icons/Menu';
import MessageSquare from '@/core/components/Icons/icons/MessageSquare';
import MoreVertical from '@/core/components/Icons/icons/MoreVertical';
import Pin from '@/core/components/Icons/icons/Pin';
import Search from '@/core/components/Icons/icons/Search';
import Seedling from '@/core/components/Icons/icons/Seedling';
import Settings from '@/core/components/Icons/icons/Settings';
import Share from '@/core/components/Icons/icons/Share';
import Sliders from '@/core/components/Icons/icons/Sliders';
import SmartPhone from '@/core/components/Icons/icons/Smartphone';
import Smile from '@/core/components/Icons/icons/Smile';
import Spinner from '@/core/components/Icons/icons/Spinner';
import Tag from '@/core/components/Icons/icons/Tag';
import TipsAndUpdates from '@/core/components/Icons/icons/TipsAndUpdates';
import Trash from '@/core/components/Icons/icons/Trash';
import Type from '@/core/components/Icons/icons/Type';
import Unarchive from '@/core/components/Icons/icons/Unarchive';
import User from '@/core/components/Icons/icons/User';
import UserCross from '@/core/components/Icons/icons/UserCross';
import Watch from '@/core/components/Icons/icons/Watch';
import XCircle from '@/core/components/Icons/icons/XCircle';

export type IconColor =
  | 'current'
  | 'primary'
  | 'placeholder'
  | 'disabled'
  | 'button-primary'
  | 'button-default'
  | 'success'
  | 'give'
  | 'danger'
  | 'brand'
  | 'moderation'
  | 'bookmark'
  | 'tag-booked'
  | 'tag-bookedDistinction'
  | 'tag-finished'
  | 'tag-refused';

const colors = {
  primary: 'stroke-content-primary text-content-primary',
  placeholder: 'stroke-content-placeholder text-content-placeholder',
  disabled: 'stroke-content-button-secondary-disable text-content-button-secondary-disable',
  'button-primary': 'stroke-content-button-primary text-content-button-primary',
  'button-default': 'stroke-content-button-secondary-default text-content-button-secondary-default',
  success: 'stroke-content-success text-content-success',
  give: 'stroke-stroke-button-give-default',
  danger: 'stroke-content-danger',
  brand: 'stroke-content-brand',
  moderation: 'stroke-content-tag-status-moderation',
  bookmark: 'stroke-[#FFC700] fill-[#FFC700]',
  current: 'stroke-current',
  'tag-booked': 'stroke-content-tag-status-booked',
  'tag-bookedDistinction': 'stroke-content-tag-status-bookedDistinction',
  'tag-finished': 'stroke-content-tag-status-finished',
  'tag-refused': 'stroke-content-tag-status-refused',
} satisfies { [key in IconColor]: string };

export type IconSize = '12' | '16' | '20' | '24' | '32' | '40';

export const sizes = {
  '12': 'h-3 w-3',
  '16': 'h-4 w-4',
  '20': 'h-5 w-5',
  '24': 'h-6 w-6',
  '32': 'h-8 w-8',
  '40': 'h-10 w-10',
} satisfies { [key in IconSize]: string };

export type IconWeight = 'light' | 'regular' | 'bold';

const weightMap = new Map<IconWeight, { [key in IconSize]: string }>([
  [
    'light',
    {
      '12': 'stroke-[1.125]',
      '16': 'stroke-[1.5]',
      '20': 'stroke-[1.875]',
      '24': 'stroke-[2.25]',
      '32': 'stroke-[3]',
      '40': 'stroke-[3.75]',
    },
  ],
  [
    'regular',
    {
      '12': 'stroke-[1.5]',
      '16': 'stroke-[3]',
      '20': 'stroke-[2.505]',
      '24': 'stroke-[3]',
      '32': 'stroke-[4.005]',
      '40': 'stroke-[4.995]',
    },
  ],
  [
    'bold',
    {
      '12': 'stroke-[2.5]',
      '16': 'stroke-[2.875]',
      '20': 'stroke-[3.12]',
      '24': 'stroke-[3.75]',
      '32': 'stroke-[4.995]',
      '40': 'stroke-[6.255]',
    },
  ],
]);

export type IconName =
  | 'add'
  | 'alert-danger'
  | 'apple'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'award'
  | 'bell'
  | 'block'
  | 'bookmark'
  | 'camera'
  | 'carousel-chevron-left'
  | 'carousel-chevron-right'
  | 'check-circle'
  | 'check-square'
  | 'check'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevrons-left'
  | 'chevrons-right'
  | 'close'
  | 'corner-down-right'
  | 'crosshair'
  | 'edit'
  | 'eye-off'
  | 'eye'
  | 'facebook'
  | 'feather'
  | 'flag'
  | 'gift'
  | 'google'
  | 'gps'
  | 'heart'
  | 'help-circle'
  | 'image'
  | 'image'
  | 'info'
  | 'lock'
  | 'log-out'
  | 'mail'
  | 'menu'
  | 'message-square'
  | 'more-vertical'
  | 'pin'
  | 'search'
  | 'seedling'
  | 'settings'
  | 'share'
  | 'sliders'
  | 'smartphone'
  | 'smile'
  | 'spinner'
  | 'tag'
  | 'tips-and-updates'
  | 'trash'
  | 'type'
  | 'user'
  | 'watch'
  | 'x-circle'
  | 'user-cross'
  | 'clock'
  | 'archive'
  | 'unarchive'
  | 'filter';

export interface IconProps {
  className: string;
}

const iconMap = new Map<IconName, React.FC<IconProps>>([
  ['alert-danger', AlertDanger],
  ['info', Info],
  ['block', Block],
  ['award', Award],
  ['user', User],
  ['check', Check],
  ['chevron-right', ChevronRight],
  ['chevron-left', ChevronLeft],
  ['chevrons-right', ChevronsRight],
  ['chevrons-left', ChevronsLeft],
  ['carousel-chevron-left', CarouselChevronLeft],
  ['carousel-chevron-right', CarouselChevronRight],
  ['arrow-left', ArrowLeft],
  ['close', Close],
  ['pin', Pin],
  ['search', Search],
  ['mail', Mail],
  ['heart', Heart],
  ['spinner', Spinner],
  ['eye', Eye],
  ['eye-off', EyeOff],
  ['crosshair', CrossHair],
  ['bell', Bell],
  ['camera', Camera],
  ['seedling', Seedling],
  ['gift', Gift],
  ['settings', Settings],
  ['help-circle', HelpCircle],
  ['log-out', LogOut],
  ['edit', Edit],
  ['lock', Lock],
  ['trash', Trash],
  ['tips-and-updates', TipsAndUpdates],
  ['message-square', MessageSquare],
  ['x-circle', XCircle],
  ['image', Image],
  ['arrow-right', ArrowRight],
  ['type', Type],
  ['tag', Tag],
  ['corner-down-right', CornerDownRight],
  ['feather', Feather],
  ['more-vertical', MoreVertical],
  ['share', Share],
  ['flag', Flag],
  ['add', Add],
  ['watch', Watch],
  ['smile', Smile],
  ['bookmark', Bookmark],
  ['arrow-up', ArrowUp],
  ['check-circle', CheckCircle],
  ['menu', Menu],
  ['smartphone', SmartPhone],
  ['sliders', Sliders],
  ['user-cross', UserCross],
  ['check-square', CheckSquare],
  ['clock', Clock],
  ['archive', Archive],
  ['unarchive', Unarchive],
  ['filter', Filter],
]);

export interface IconsProps {
  icon: IconName;
  size?: IconSize;
  weight?: IconWeight;
  color?: IconColor;
  onClick?: () => void;
  label?: string;
}

const Icons: React.FC<IconsProps> = ({ icon, size = '24', weight = 'regular', color = 'primary', onClick, label }) => {
  const computedClasses = useMemo(() => {
    const sizeClass = sizes[size];
    const colorClass = colors[color];
    const w = weightMap.get(weight);
    const weightClass = w ? w[size] : '';

    return [sizeClass, colorClass, weightClass].join(' ');
  }, [size, color, weight]);

  const Icon = useMemo(() => iconMap.get(icon), [icon]);

  const Wrapper = useMemo(() => {
    if (!onClick) return ({ children }: PropsWithChildren) => children;

    return function W({ children }: PropsWithChildren) {
      return (
        <button type="button" aria-label={label} onClick={onClick}>
          {children}
        </button>
      );
    };
  }, [onClick, label]);

  if (!Icon) return null;

  return (
    <Wrapper>
      <Icon className={`${computedClasses}`} />
    </Wrapper>
  );
};

export default Icons;
