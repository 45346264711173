import React from 'react';

import DonationForm from '@/core/components/forms/DonationForm';
import Modal from '@/core/components/Modal';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useGlobalContext } from '@/core/lib/global/global.context';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';

const CreateDonation: PageWithLayoutsAndProviders = () => {
  const {
    give: { isOpen, onClose },
  } = useGlobalContext();
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DonationForm />
    </Modal>
  );
};

export default CreateDonation;
