import React, { createContext, useContext, useMemo } from 'react';

import useDisclosure from '@/core/hooks/useDisclosure';

export interface GlobalContextType {
  give: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

export const useGlobalContext = () => useContext(GlobalContext) as GlobalContextType;

const GlobalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const value = useMemo(
    () => ({
      give: { isOpen, onOpen, onClose },
    }),
    [isOpen],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
