// const DonationPageParams = yup.object({ id: yup.number().required() });
// const donation = makeRoute(({ id }) => `/don/${id}`, DonationPageParams);
import omit from 'lodash/omit';
import * as yup from 'yup';

import type { CommonSearchParams, UseParamsReturn } from '@/core/lib/router/route';
import { AbstractRoute } from '@/core/lib/router/route';

class ProfileRoute extends AbstractRoute {
  static path = '/profil';

  getPath() {
    return ProfileRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileRoute(common);
  }

  isPrivate() {
    return ProfileRoute.isPrivate;
  }

  resolve() {
    return `/profil${this.computeParams()}`;
  }
}

const publicProfilePageParamsSchema = yup.object({ uuid: yup.string().required() });

class PublicProfileRoute extends AbstractRoute {
  private uuid: string;

  getPath() {
    return PublicProfileRoute.path;
  }

  static path = '/profil/public/:uuid';

  static isPrivate = false;

  constructor(uuid: string, common?: CommonSearchParams) {
    super(common);
    this.uuid = uuid;
  }

  static useParams(): UseParamsReturn<typeof publicProfilePageParamsSchema> {
    const { common, query } = AbstractRoute.useCommonParams();
    const page = publicProfilePageParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page, search: {}, common };
  }

  static init(url: URL): PublicProfileRoute {
    const params = this.exec(url.pathname);
    const casted = publicProfilePageParamsSchema.cast(params, { stripUnknown: true, assert: true });

    const common = this.searchParamsToCommon(url.searchParams);

    return new PublicProfileRoute(casted.uuid, common);
  }

  isPrivate() {
    return PublicProfileRoute.isPrivate;
  }

  resolve() {
    return `/profil/public/${this.uuid}${this.computeParams()}`;
  }
}

class ProfileHelpRoute extends AbstractRoute {
  static path = '/profil/aide';

  getPath() {
    return ProfileHelpRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileHelpRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileHelpRoute(common);
  }

  isPrivate() {
    return ProfileHelpRoute.isPrivate;
  }

  resolve() {
    return `/profil/aide${this.computeParams()}`;
  }
}

class ProfileCounterRoute extends AbstractRoute {
  static path = '/profil/compteur';

  getPath() {
    return ProfileCounterRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileCounterRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileCounterRoute(common);
  }

  isPrivate() {
    return ProfileCounterRoute.isPrivate;
  }

  resolve() {
    return `/profil/compteur${this.computeParams()}`;
  }
}
class ProfilePostsRoute extends AbstractRoute {
  static path = '/profil/mes-annonces';

  getPath() {
    return ProfilePostsRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfilePostsRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfilePostsRoute(common);
  }

  isPrivate() {
    return ProfilePostsRoute.isPrivate;
  }

  resolve() {
    return `/profil/mes-annonces${this.computeParams()}`;
  }
}

class ProfileSettingsRoute extends AbstractRoute {
  static path = '/profil/parametres';

  getPath() {
    return ProfileSettingsRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileSettingsRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileSettingsRoute(common);
  }

  isPrivate() {
    return ProfileSettingsRoute.isPrivate;
  }

  resolve() {
    return `/profil/parametres${this.computeParams()}`;
  }
}
class ProfileModifyRoute extends AbstractRoute {
  static path = '/profil/modifier';

  getPath() {
    return ProfileModifyRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileModifyRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileModifyRoute(common);
  }

  isPrivate() {
    return ProfileModifyRoute.isPrivate;
  }

  resolve() {
    return `/profil/modifier${this.computeParams()}`;
  }
}
class ProfileNotificationsRoute extends AbstractRoute {
  static path = '/profil/notifications';

  getPath() {
    return ProfileNotificationsRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileNotificationsRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileNotificationsRoute(common);
  }

  isPrivate() {
    return ProfileNotificationsRoute.isPrivate;
  }

  resolve() {
    return `/profil/notifications${this.computeParams()}`;
  }
}
class ProfileEmailRoute extends AbstractRoute {
  static path = '/profil/email';

  getPath() {
    return ProfileEmailRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileEmailRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileEmailRoute(common);
  }

  isPrivate() {
    return ProfileEmailRoute.isPrivate;
  }

  resolve() {
    return `/profil/email${this.computeParams()}`;
  }
}
const profilEmailCodeSearchParamsSchema = yup.object({ email: yup.string().optional() });

class ProfileEmailCodeRoute extends AbstractRoute {
  private email?: string;

  static path = '/profil/email/code';

  getPath() {
    return ProfileEmailCodeRoute.path;
  }

  static isPrivate = true;

  constructor(email?: string, common?: CommonSearchParams) {
    super(common);
    this.email = email;
  }

  static useParams(): UseParamsReturn {
    const { common, query } = AbstractRoute.useCommonParams();
    const search = profilEmailCodeSearchParamsSchema.cast(query, { stripUnknown: true, assert: false });
    return { page: {}, search, common };
  }

  static init(url: URL): ProfileEmailCodeRoute {
    const all = Array.from(url.searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as CommonSearchParams);

    const { email } = profilEmailCodeSearchParamsSchema.cast(all, { stripUnknown: true, assert: true });

    return new ProfileEmailCodeRoute(email, omit(all, 'email'));
  }

  isPrivate() {
    return ProfileEmailCodeRoute.isPrivate;
  }

  resolve() {
    return `/profil/email/code?${this.computeParams({ email: this.email })}`;
  }
}

class ProfilePasswordRoute extends AbstractRoute {
  static path = '/profil/mot-de-passe';

  getPath() {
    return ProfilePasswordRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfilePasswordRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfilePasswordRoute(common);
  }

  isPrivate() {
    return ProfilePasswordRoute.isPrivate;
  }

  resolve() {
    return `/profil/mot-de-passe${this.computeParams()}`;
  }
}

class ProfileBlockedUsersRoute extends AbstractRoute {
  static path = '/profil/utilisateurs-bloques';

  getPath() {
    return ProfileBlockedUsersRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileBlockedUsersRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileBlockedUsersRoute(common);
  }

  isPrivate() {
    return ProfileBlockedUsersRoute.isPrivate;
  }

  resolve() {
    return `/profil/utilisateurs-bloques${this.computeParams()}`;
  }
}

class ProfileDeleteAccountRoute extends AbstractRoute {
  static path = '/profil/supprimer-mon-compte';

  getPath() {
    return ProfileDeleteAccountRoute.path;
  }

  static isPrivate = true;

  static useParams(): UseParamsReturn {
    const { common } = AbstractRoute.useCommonParams();
    return { page: {}, search: {}, common };
  }

  static init(url: URL): ProfileDeleteAccountRoute {
    const common = this.searchParamsToCommon(url.searchParams);

    return new ProfileDeleteAccountRoute(common);
  }

  isPrivate() {
    return ProfileDeleteAccountRoute.isPrivate;
  }

  resolve() {
    return `/profil/supprimer-mon-compte${this.computeParams()}`;
  }
}

export default {
  ProfileRoute,
  PublicProfileRoute,
  ProfileHelpRoute,
  ProfileCounterRoute,
  ProfilePostsRoute,
  ProfileSettingsRoute,
  ProfileModifyRoute,
  ProfileNotificationsRoute,
  ProfileEmailRoute,
  ProfileEmailCodeRoute,
  ProfilePasswordRoute,
  ProfileBlockedUsersRoute,
  ProfileDeleteAccountRoute,
};
